import { Timestamp } from '@grpc/grpc-js/build/src/generated/google/protobuf/Timestamp';
import {
  Author,
  Badge,
  ContentFilter,
  Image,
  Organization,
  PostCategory,
  PostHashTag,
} from '@interfaces/common.interface';
import { Summary } from '@interfaces/summary.interface';
import { CollectionDetails, LibraryItem } from './library.interface';

export interface GetFeedRequest {
  page_number: number;
  page_size: number;
  filter?: ContentFilter;
}

export interface GetFeedResponse {
  posts: Post[];
  totalData: number;
}

export interface GetSearchDataRequest {
  last_post_id: string;
  page_size: number;
  author: Author[];
  hashtags: PostHashTag[];
  categories: PostCategory[];
  badges: Badge[];
}

export interface GetSearchDataResponse {
  posts: PostItem[] | LibraryItem[];
}

export interface GetFeedSearchRequest {
  filter?: ContentFilter;
  page_size: number;
  page_number: number;
}

export interface GetFeedSearchResponse {
  posts: PostItem[] | LibraryItem[];
  totalData: number;
  translation: TranslatedItem;
}

export interface TranslatedItem {
  translated: string;
  detected: string;
}
export interface GetPostRequest {
  post_id: string;
  item_url: string;
}

export interface GetPostResponse {
  post: PostItem;
}

export interface PutPostRequest {
  post: Post;
}

export interface PutPostResponse {
  post_id: string;
}

export interface UpdatePostRequest {
  post: Post;
}

export interface UpdatePostResponse {
  post_id: string;
}

export interface DeletePostRequest {
  post: Post;
}

export interface DeletePostResponse {
  success: boolean;
}

export interface ApplyPostActionRequest {
  action: PostActionApply;
}

export interface ApplyPostActionResponse {
  actionSummary: ActionSummary;
}

export interface GetPostActionSummaryRequest {
  post_id: string;
}

export interface GetPostActionSummaryResponse {
  action_summaries: ActionSummaries;
}

export interface GetBadgeRequest {
  post_type?: PostType[];
}

export interface GetBadgeResponse {
  badges: Badge[];
}

export interface PutBadgeRequest {
  badge: Badge;
}

export interface PutBadgeResponse {
  id: string;
}

export interface GetCategoryRequest {}

export interface GetCategoryResponse {
  categories: PostCategory[];
}

export interface PutCategoryRequest {
  category: PostCategory;
}

export interface PutCategoryResponse {
  id: string;
}

export interface GetHashtagRequest {}

export interface GetHashtagResponse {
  hashtags: PostHashTag[];
}

export interface PutHashtagRequest {
  hashTag: PostHashTag;
}

export interface PutHashtagResponse {
  id: string;
}

export interface PostItem {
  post: Post;
}

export interface Post {
  post_id: number;
  post_type: PostType;
  post_status: PostStatus;
  meta: PostMeta;
  badge?: Badge[];
  categories?: PostCategory[];
  hashtags?: PostHashTag[];
  post_content?: PostContent;
  summary?: Summary;
  document?: Document;
  summaries?: ActionSummaries;
  my_summaries?: ActionSummaries;
  saved?: boolean;
  pinned?: boolean;
  uploads?: PostUploads[];
  reference?: PostReference[];
  collections?: CollectionDetails[];
}

export interface PostReference {
  post_id: number;
  meta_url: string;
}

export interface PostUploads {
  file_url: string;
  file_type: string;
}

// converting same as backend
export enum PostType {
  NONE = 0,
  POST,
  SUMMARY,
  ACT,
  ANNOUNCEMENT,
  REGULATION,
  USER_DOCUMENT,
}

export enum PostStatus {
  PENDING = 0,
  ACTIVE,
  ARCHIVED,
  DELETED,
}

export interface PostMeta {
  create_user_id: string;
  user_name: string;
  display_image: string;
  create_date_time: Timestamp | number | string;
  edit_date_time: Timestamp | number;
  item_url: string;
  is_org?: boolean;
  publish_date: number | string;
  // publish_date: Timestamp | number | string;
  organization?: Organization;
}

export interface PostContent {
  content_id: string;
  title: string;
  sub_title: string;
  details: Node;
  images?: Image[];
  links?: string[];
  contains_embed?: boolean;
  embed_post_id?: string; // Embed LEX AI summaries/full-documents in a post
  string_details?: string;
  // PostType.ACT
  celex?: string;
  act_type?: string;
  src_url?: string;
  pdf_url?: string;
}

// Actions that can be performed on a post by end users
export interface PostActionApply {
  post_type: PostType;
  post_id: string;
  actionApplyType: PostActionApplyType;
  actionType: PostActionType;
}

export interface ActionSummary {
  actionType: PostActionType;
  count: number;
}

export interface ActionSummaries {
  summary: ActionSummary[];
}

export enum PostActionType {
  LIKE,
  SAVE,
  COMMENT,
  SHARE,
  REPORT_ABUSE,
}

export enum PostActionApplyType {
  ACTION_APPLY_TYPE_ADD,
  ACTION_APPLY_TYPE_REMOVE,
}
