import { Timestamp } from '@grpc/grpc-js/build/src/generated/google/protobuf/Timestamp';
import { NotificationFilter } from '@interfaces/account.interface';
import { Author, Badge, ContentFilter, PostCategory, PostHashTag } from '@interfaces/common.interface';
import { CollectionDetails, LibraryItem } from '@interfaces/library.interface';
import { Post, PostItem, PostMeta, PostType } from '@interfaces/post.interface';
import { WorkspaceAccess, Workspaces } from '@interfaces/workspace.interface';

export const DATE_MIN = new Date('1970-01-01T00:00:00.000Z');

const PAGE_SIZE = Number.parseInt(process.env.NEXT_PUBLIC_PAGINATION_SIZE);

export const CHATBOT_WHITELIST = [PostType.POST, PostType.SUMMARY, PostType.USER_DOCUMENT];

export const DEFAULT_IMAGE_PROFILE_URL =
  'https://s3.eu-central-1.amazonaws.com/data.lexai.co/authors/default_avatar.png';

export const NARROW_SCREEN = 768;

const MARK_HIGHLIGHT = '<mark style="background-color: #B5FFd9;">$1</mark>';

const HASHTAG_ID_TO_BE_UPDATED = 556;

export const FEATURE_BUBBLE_MODEL = 'ENABLE_BUBBLE_MODEL';
export const FEATURE_CHATBOT = 'ENABLE_CHATBOT';
export const FEATURE_USER_DOCUMENTS = 'ENABLE_USER_DOCUMENTS';
export const FEATURE_ACT_SEARCH = 'FEATURE_ACT_SEARCH';

const FEATURE_WHITELIST = [FEATURE_CHATBOT];

function isFeatureFlagEnabled(workspace: Workspaces, flag: string): boolean {
  if (process.env.NEXT_PUBLIC_LEX_DEV) {
    return true;
  }

  if (FEATURE_WHITELIST.includes(flag)) {
    return true;
  }

  const isEnabled = (workspace?.feature_flags || []).includes(flag);
  return isEnabled;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

const timeout = (delay: number) => {
  return new Promise((res) => setTimeout(res, delay));
};

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result == 'string') {
        resolve(reader.result);
      } else {
        reject('Image processing failed');
      }
    };
    reader.onerror = reject;
  });

const getAccessLevel = (val: WorkspaceAccess): string => {
  switch (val) {
    case WorkspaceAccess.OWNER:
      return 'Owner';
    case WorkspaceAccess.ADMIN:
      return 'Admin';
    default:
      return 'Member';
  }
};

const filterExists = (savedFilters, filterData) => {
  return savedFilters.some((existingFilter) => {
    return JSON.stringify(existingFilter) === JSON.stringify(filterData);
  });
};

const hasUserAnyInterests = (profile) => {
  return profile?.interests?.categories.length > 0;
};

// sort function to put hashtag in type status order
const priorityOrder = [
  // Type:
  '#REGULATION',
  '#DIRECTIVE',
  '#IMPLEMENTINGACT',
  '#DELEGATEDACT',
  '#COMMUNICATION',
  '#GUIDANCE',
  '#ORDINANCE',
  '#CASESTUDY',
  '#COURTDECISION',
  '#ACT',
  '#PR',
  '#WHITEPAPER',
  '#STRATEGYPAPER',
  '#BILL',
  '#INTERNATIONALAGREEMENT',
  '#RESOLUTION',
  '#NOTICE',
  '#DECISION',
  '#COMMUNIQUE',
  '#ANNOUNCEMENT',
  '#STATEMENT',
  '#LAW',
  '#TREATY',
  // Status:
  '#PROPOSED',
  '#ADOPTED',
  '#ENFORCED',
  '#REPEALED',
  '#AMENDED',
  '#PUBLISHED',
  '#APPROVED',
];

function changeOrderOfHashtags(a, b) {
  // uppercase to double sure we check case insensitive
  const indexA = priorityOrder.indexOf(a.hashtag_description.toUpperCase());
  const indexB = priorityOrder.indexOf(b.hashtag_description.toUpperCase());

  if (indexA === -1 && indexB === -1) {
    // Both hashtags are not in the priority order, so leave it as it is
    return 0;
  } else if (indexA === -1) {
    // a is not in the priority order, b is in the priority order
    return 1;
  } else if (indexB === -1) {
    // b is not in the priority order, a is in the priority order
    return -1;
  } else {
    // Both hashtags are in the priority order, sort by their order in the priority list
    return indexA - indexB;
  }
}

const savedFilterDefaultName = (filter: ContentFilter): string => {
  return (
    filter?.authors[0]?.name ||
    filter?.badges[0]?.badge_name ||
    filter?.hashtags[0]?.hashtag_description ||
    filter?.categories?.[0]?.category_name ||
    filter?.collections?.[0]?.collection_name ||
    filter?.search_term ||
    'My search' // if nothing found then empty result, this won't be possible but just for fallback
  );
};

const isSafariBrowser = () => {
  const userAgent = navigator.userAgent;
  const vendor = navigator.vendor;
  return /Safari/.test(userAgent) && /Apple/.test(vendor) && !/CriOS/.test(userAgent) && !/FxiOS/.test(userAgent);
};

const isFilterApplied = (filters: ContentFilter): boolean => {
  return (
    (filters.collections && filters.collections.length > 0) ||
    (filters.badges && filters.badges.length > 0) ||
    (filters.hashtags && filters.hashtags.length > 0) ||
    (filters.categories && filters.categories.length > 0) ||
    (filters.authors && filters.authors.length > 0) ||
    (filters.search_term && filters.search_term.trim().length > 0) ||
    (filters.start_date !== undefined && filters.start_date !== null) ||
    (filters.end_date !== undefined && filters.end_date !== null)
  );
};

// this function is to sort array by publish date
const sortByDate = function (rows, getPostMeta = (data) => data) {
  const sortedRows = rows.sort((a: { data?: any }, b: { data?: any }) => {
    // let's get the first and second data
    const firstEle = getPostMeta(a);
    const secondEle = getPostMeta(b);

    // Extract dates from the rows, checking both fields
    const dateA: Date = new Date(firstEle.post.meta.publish_date || firstEle.post.meta.create_date_time);
    const dateB: Date = new Date(secondEle.post.meta.publish_date || secondEle.post.meta.create_date_time);

    // Compare the dates in descending order (newest first)
    return dateB.getTime() - dateA.getTime();
  });
  return sortedRows;
};

const convertToLocalDate = (date) => {
  return isNaN(Date.parse(date)) ? date || 'N/A' : new Date(date).toLocaleDateString('en-GB');
};

const convertDateToLocalString = function (date) {
  const dateObj = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return dateObj.toLocaleDateString('en-US', options);
};

const contentFilterFromNotifictionFilter = (filter: NotificationFilter): ContentFilter => {
  return {
    collections: filter?.collection_ids?.map((c) => ({ collection_id: `${c}` }) as CollectionDetails),
    authors: filter?.author_org_ids?.map((a) => ({ id: `${a}` }) as Author),
    hashtags: filter?.hashtag_ids?.map((h) => ({ hashtag_id: h }) as PostHashTag),
    categories: filter?.category_ids?.map((c) => ({ category_id: c }) as PostCategory),
    badges: filter?.badge_ids?.map((b) => ({ badge_id: b }) as Badge),
    search_term: filter?.search_term,
  };
};

class CarouselItem {
  key: string;
  image: string[];
  title: string;
  meta: PostMeta;
  link: string;
}

const carouselItemsFromPost = (posts: PostItem[] | LibraryItem[]): CarouselItem[] => {
  const items: CarouselItem[] = [];
  for (const p of posts) {
    const summary = p as unknown as LibraryItem;
    // Summaries have one more level of nesting with "post" and "collections" objects
    // QUESTION: Add one level of nesting to News too, for consistency?
    if (summary?.post?.post_type == PostType.SUMMARY) {
      items.push({
        key: `${summary?.post?.post_id}`,
        image: [],
        title: summary?.post?.summary?.details?.string_title,
        meta: summary?.post?.meta,
        link: summary?.post?.meta?.item_url,
      });
    }
    const post = p as unknown as PostItem;
    if (post?.post?.post_type == PostType.POST) {
      const p = post?.post;
      items.push({
        key: `${p?.post_id}`,
        image: p?.post_content?.images?.map((image) => image.url),
        title: p?.post_content?.title,
        meta: p?.meta,
        link: p?.meta?.item_url,
      });
    }
  }
  return items;
};

const dateFromAny = (date: Date | Timestamp | string | number): Date => {
  // console.log('dateFromAny', date, typeof date);
  let publishDate: Date;
  if (typeof date == 'object') {
    publishDate = date as Date;
  } else if (typeof date == 'string') {
    publishDate = new Date(date);
  } else if (typeof date == 'number') {
    publishDate = new Date(date);
  } else {
    const timeStamp: Timestamp = date as Timestamp;
    publishDate = new Date(parseInt(timeStamp.seconds.toString()) * 1000);
  }
  return publishDate;
};

const isNews = (p: PostItem | LibraryItem): boolean => {
  const postItem = p as unknown as PostItem;
  if (postItem?.post?.post_type == PostType.POST) {
    return true;
  }
  const post = p as unknown as Post;
  if (post?.post_type == PostType.POST) {
    return true;
  }
  return false;
};

const isSummary = (p: PostItem | LibraryItem) => {
  const summary = p as unknown as LibraryItem;
  if (summary?.post?.post_type == PostType.SUMMARY) {
    return true;
  }
};

const isPostType = (p: PostItem | LibraryItem, type: PostType) => {
  const post = p as unknown as Post;
  if (post?.post_type == type) {
    return true;
  }
  const postItem = p as unknown as PostItem;
  if (postItem?.post?.post_type == type) {
    return true;
  }
  const summary = p as unknown as LibraryItem;
  if (summary?.post?.post_type == type) {
    return true;
  }
  return false;
};

const getPublishDate = (p: Post | LibraryItem) => {
  // FIXME: This is a temporary solution to get the publish date of a post
  const summary = p as unknown as LibraryItem;
  if (summary?.post?.post_type == PostType.SUMMARY) {
    return dateFromAny(summary?.post?.meta?.publish_date);
  }
  const postItem = p as unknown as PostItem;
  if (postItem?.post?.post_type == PostType.POST) {
    return dateFromAny(postItem?.post?.meta?.publish_date);
  }
  const post = p as unknown as Post;
  if (post?.post_type == PostType.POST) {
    return dateFromAny(post?.meta?.publish_date);
  }
  return null;
};

function compCollectionsAlfabetically(): (a: CollectionDetails, b: CollectionDetails) => number {
  return function (colleactionA, collectionB) {
    let collectionName1 = colleactionA.collection_name.toLowerCase();
    let collectionName2 = collectionB.collection_name.toLowerCase();

    if (collectionName1 < collectionName2) return -1;
    if (collectionName1 > collectionName2) return 1;
    return 0;
  };
}

class PostMetadata {
  postId: number;
  postType: PostType;
  title: string;
  postUrl: string;
  publishDate: Date;
  orgName: string;
  orgUrl: string;
  orgImgUrl: string;
}

export const getPostMetadata = (post: PostItem | LibraryItem): PostMetadata => {
  const postId = post?.post?.post_id;
  const isSummary = post?.post?.post_type === PostType.SUMMARY;
  const postType = post?.post?.post_type || PostType.POST;
  const title = isSummary ? post.post.summary?.details?.string_title : post.post?.['post_content']?.['title'];

  const url = post?.post?.meta?.organization?.url_slug ?? '';
  let orgUrl = url === '' ? '' : `/source/${url}`;
  const orgName = post?.post?.meta?.organization?.org_name ?? '';
  const postUrl = post?.post?.meta?.item_url ?? '';
  const orgImgUrl = isAbsoluteUrl(post?.post?.meta?.organization?.org_image_url?.url)
    ? post?.post?.meta?.organization?.org_image_url?.url
    : '/static/image/logo/default_avatar.png';
  const publishDate = getPublishDate(post);

  return { postId, postType, postUrl, title, publishDate, orgName, orgUrl, orgImgUrl } as PostMetadata;
};

const isAbsoluteUrl = (url: string) => {
  return /^(?:[a-zA-Z]+:)?\/\//.test(url);
};

const subtractMonths = (numOfMonths, date = new Date()) => {
  const clonedDate = new Date(date);
  clonedDate.setMonth(date.getMonth() - numOfMonths);

  return clonedDate;
};

const getTheNextDateToSearch = (endDateStr, minDate, duration) => {
  // debugger;
  const lastDate = new Date(minDate);

  const endDate = new Date(endDateStr);
  const startDate = subtractMonths(duration, endDate);

  if (startDate < lastDate || endDate < lastDate) {
    return {
      start_date: Math.floor(new Date(lastDate).getTime()),
      end_date: Math.floor(new Date(endDate).getTime()),
    };
  }
  return {
    start_date: Math.floor(new Date(startDate).getTime()),
    end_date: Math.floor(new Date(endDate).getTime()),
  };
};

export {
  CarouselItem,
  carouselItemsFromPost,
  changeOrderOfHashtags,
  classNames,
  compCollectionsAlfabetically,
  contentFilterFromNotifictionFilter,
  convertDateToLocalString,
  convertToLocalDate,
  dateFromAny,
  filterExists,
  getAccessLevel,
  getPublishDate,
  getRandomInt,
  getTheNextDateToSearch,
  HASHTAG_ID_TO_BE_UPDATED,
  isAbsoluteUrl,
  isFeatureFlagEnabled,
  isFilterApplied,
  isNews,
  isPostType,
  isSafariBrowser,
  isSummary,
  hasUserAnyInterests,
  MARK_HIGHLIGHT,
  PAGE_SIZE,
  savedFilterDefaultName,
  sortByDate,
  timeout,
  toBase64,
};
